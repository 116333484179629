<template>
  <FormModal class="form-modal--verify-phone">
    <template #body>
      <NavigationBar />

      <PhoneVerifyFormStep
        ref="phoneVerify"
        :next-step="nextStep"
        :twilio-service-sid="twilioServiceSid"
        :phone-number="phoneNumber"
        :progress-value="progressValue"
      />
    </template>

    <template #footer>
      <div />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import NavigationBar from 'chimera/all/themes/blueflow/components/layout/NavigationBar'
import PhoneVerifyFormStep from 'chimera/all/components/form/steps/phoneVerify/PhoneVerifyFormStep.vue'
import ThankYouPage from '~/pages/solicitudes-de-cotizacion/solicitud-completada.vue'

export default {
  components: {
    NavigationBar,
    PhoneVerifyFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Revisa tu teléfono',
      headDescription:
        'Introduce tus datos para que podamos ponerte en contacto con el especialista más adecuado de tu zona.',
      path: '/solicitudes-de-cotizacion/revisa-tu-telefono',
      progressValue: 90,
      twilioServiceSid: process.env.twilioServiceSid,
      nextStep: ThankYouPage,
    }
  },

  computed: {
    /**
     * @returns {*}
     */
    phoneNumber() {
      return this.$store.getters['lead/getData']('phone', '')
    },
  },

  /**
   */
  beforeMount() {
    this.setCheckoutStep(4)
  },
}
</script>
